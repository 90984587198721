import React, {useState, useRef, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useTheme} from 'styled-components';
import {ItemBlock} from '../../components/itemBlock/itemBlock';
import {Dropdown, DropZoneWrapper, SetupImage} from '../../components';
import TrashIcon from '../../assets/icons/trash.svg';
import {getSessionHashCode} from '../layout';
import {showErrorNotification} from '../../helpers/errorHelper';
import {
  classNames,
  generateHashCode,
  getFileByDataUrl,
  getImageDimension,
  getImageUrlWithoutSuffix,
  getWindowImage,
  objectTypes,
} from '../../helpers/canvasHelper';
import {uploadTemplateImage} from '../../api/helpers/integration';
import {pixelsToCm} from '../../helpers/unitHelper';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faUpDownLeftRight,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import {
  BACKGROUND_TAB,
  getSideBarMenuList,
  IMAGE_TAB,
  TEXT_TAB,
} from './constants/sidebarMenu';
import {
  getLeft,
  getTop,
  setLeftByPercentage,
  setTopByPercentage,
  updateOffsets,
} from '../../helpers/positionHelper';
import {PreviewObject} from '../../components/previewObject/PreviewObject';
import {getPersonalizationInput} from '../../helpers/inputsHelper';
import * as S from './styled';
import {
  getImageDpiByFile,
  patchOverSizedImages,
} from '../../helpers/imageHelper';

const supportedDPIs = ['96', '150', '200', '300'];
const uploadLimit = 150; // Mb

const mbToBytes = mb => mb * 1024 * 1024;

const getInitialText = settings => ({
  className: classNames.text,
  type: objectTypes.text,
  name: `Text-${generateHashCode(new Date().toLocaleString())}`,
  params: {
    text: 'new text',
    fontSize: Math.min(settings?.width, settings?.height) / 8,
    x: settings?.width / 2,
    y: settings?.height / 2,
    fontFamily: 'Arial',
    // predefinedColors: [
    //   'white',
    //   'yellow',
    //   'orange',
    //   'red',
    //   'green',
    //   'blue',
    //   'black',
    // ],
    // textVariants: [
    //   {name: 'text1', text: 'text1'},
    //   {name: 'text2', text: 'text2'},
    // ],
  },
  personalization: [
    'text',
    'fontSize',
    'fontStyle',
    'fontFamily',
    'fill',
    'background',
    // 'left',
    // 'top',
    'align',
    'scale',
    'brightness',
    'rotation',
    // 'position',
    'move',
    // 'predefinedColors',
    // 'textVariants',
  ],
});
const getInitialImage = settings => ({
  className: classNames.image,
  type: objectTypes.image,
  key: new Date().getTime(),
  name: `Image-${generateHashCode(new Date().toLocaleString())}`,
  params: {
    image: null,
    fillPatternImage: null,
    fillPatternRepeat: 'no-repeat',
    x: settings?.width / 2,
    y: settings?.height / 2,
    scaleX: 1,
    scaleY: 1,
    rotation: 0,
  },
  personalization: [
    // 'image',
    'fillPatternImage',
    'filters',
    // 'width',
    // 'height',
    // 'left',
    // 'top',
    'scale',
    'brightness',
    'repeat',
    'rotation',
    // 'position',
    'move',
    'mirror',
  ],
});

export const Sidebar = props => {
  const {
    showNotification,
    cId,
    total,
    objects = [],
    groups = [],
    settings,
    onUpdatePersonalizationParam,
    onUpdatePersonalizationParams,
    onDeleteObject,
    onAddObject,
    customFonts,
    selectedObject,
    setSelectedObject,
    onUpdateSettings,
    setLoading,
    activeTab,
    setActiveTab,
    isPattern,
    presetPersonalization,
  } = props;
  const {t} = useTranslation(['sidebar']);
  const theme = useTheme();
  const [quality, setQuality] = useState(null);
  //const [uploadLoading, setUploadLoading] = useState(false);
  const [rotationValue, setRotationValue] = useState(0);
  const [showSetupImage, setShowSetupImage] = useState(false);
  const setupImageRef = useRef(null);
  const previewRef = useRef(null);
  const selectedUnit = useSelector(state => state.settings.unit);
  const sidebarMenuList = useMemo(
    () => getSideBarMenuList(objects, isPattern, theme),
    [objects, isPattern, theme],
  );
  const bgObject = useMemo(
    () => objects.find(i => i?.type === objectTypes.background),
    [objects],
  );
  const isActivePattern = activeTab === BACKGROUND_TAB && isPattern;

  useEffect(() => {
    if (selectedObject?.id) {
      const type = selectedObject?.type;
      if (type === objectTypes.text && activeTab !== TEXT_TAB) {
        setActiveTab(TEXT_TAB);
      }
      if (
        [objectTypes.image, objectTypes.clipart].includes(type) &&
        activeTab !== IMAGE_TAB
      ) {
        setActiveTab(IMAGE_TAB);
      }
    }
  }, [selectedObject]);

  useEffect(() => {
    if (activeTab === 4 && selectedObject?.type !== objectTypes.background) {
      setSelectedObject(objects.find(i => i?.type === objectTypes.background));
    }
  }, [activeTab]);

  useEffect(() => {
    // set initial tab
    setActiveTab(sidebarMenuList?.[0]?.id);
  }, []);

  const isBackgroundImageChanged = () => {
    const bg = objects.find(i => i?.type === objectTypes.background);
    const presetBg = (presetPersonalization || []).find(
      i => i?.type === objectTypes.background,
    );
    return (
      (bg?.params?.fillPatternImage?.src &&
        bg?.params?.fillPatternImage?.src !==
          presetBg?.params?.fillPatternImage?.src) ||
      (bg?.params?.image?.src &&
        bg?.params?.image?.src !== presetBg?.params?.image?.src)
    );
  };

  const isBackgroundWithoutSource = () => {
    const bg = objects.find(i => i?.type === objectTypes.background);
    const isImagePersonalization =
      (bg?.personalization || []).includes('image') ||
      (bg?.personalization || []).includes('fillPatternImage');
    return (
      !(bg?.params?.fillPatternImage?.src || bg?.params?.image?.src) &&
      isImagePersonalization &&
      activeTab === BACKGROUND_TAB
    );
  };

  const getImagePersonalizationParam = o => {
    if ((o?.personalization || []).includes('image')) {
      return 'image';
    } else if ((o?.personalization || []).includes('fillPatternImage')) {
      return 'fillPatternImage';
    } else {
      return 'fillPatternImage';
    }
  };

  const getNewId = () => {
    let maxId = 0;
    objects.forEach(i => {
      if (i?.id > maxId) {
        maxId = i?.id;
      }
    });
    return maxId + 1;
  };

  const addObject = v => {
    const id = getNewId();
    const key = `id=${id},Math=${Math.random()}`;
    updateOffsets(v?.className, v?.params);
    const newObject = {
      ...v,
      id,
      key,
    };
    onAddObject(newObject, () => {
      setSelectedObject(newObject);
    });
  };

  const getMaxLengthLabel = (o, param) => {
    return param === 'text' && o?.params?.maxLength
      ? ` (Max ${o?.params?.maxLength} characters)`
      : '';
  };

  const isRepeatedGroupParam = (o, pItem) => {
    if (
      o?.params?.group &&
      groups.find(i => i?.name === o?.params?.group)?.params.includes(pItem)
    ) {
      const groupObjects = objects.filter(
        i =>
          i?.params?.group === o?.params.group &&
          o.personalization.includes(pItem),
      );
      const groupIndex = groupObjects.findIndex(i => i === o);
      return groupIndex !== 0;
    }
    return false;
  };

  const onChangeImage = (object, param, url) => {
    const onApply = dpi => {
      const image = getWindowImage(url);
      getImageDimension(url, ({width, height}) => {
        const useBackgroundSizeTerms =
          (settings?.useBackgroundImageSize || isPattern) &&
          object?.type === objectTypes.background;
        if (useBackgroundSizeTerms) {
          onUpdateSettings({width, height, dpi});
        }
        const iWidth = object?.params?.width || width;
        const iHeight = object?.params?.height || height;
        const changedParams = {
          image: param === 'image' ? image : undefined,
          fillPatternImage: param === 'fillPatternImage' ? image : undefined,
          imageSrc: param === 'image' ? url : undefined,
          fillPatternImageSrc: param === 'fillPatternImage' ? url : undefined,
          width:
            (settings?.useBackgroundImageSize || isPattern) &&
            object?.type === objectTypes.background
              ? width
              : iWidth,
          height:
            (settings?.useBackgroundImageSize || isPattern) &&
            object?.type === objectTypes.background
              ? height
              : iHeight,
          x: useBackgroundSizeTerms
            ? iWidth / 2
            : object?.params?.x || settings.width / 2,
          y: useBackgroundSizeTerms
            ? iHeight / 2
            : object?.params?.y || settings.height / 2,
          // reset scale to default 1
          scaleX: 1,
          scaleY: 1,
          // fix offset
          offsetX: iWidth / 2,
          offsetY: iHeight / 2,
        };
        object.params = {
          ...object?.params,
          ...changedParams,
        };
        if (object?.type !== objectTypes.background) {
          patchOverSizedImages(object);
        }
        if (param === 'fillPatternImage') {
          const fillPatternParams = {
            fillPatternX: undefined,
            fillPatternY: undefined,
            fillPatternOffsetX: undefined,
            fillPatternOffsetY: undefined,
            fillPatternScaleX: undefined,
            fillPatternScaleY: undefined,
            fillPatternRotation: 0,
          };
          object.params = {
            ...object?.params,
            ...fillPatternParams,
          };
        }
        setTimeout(() => {
          setSelectedObject({
            ...object,
            key: new Date().getTime(),
            params: {...object?.params},
          });
          if (object?.id) {
            onUpdatePersonalizationParams(object, object?.params);
          }
          if (param === 'fillPatternImage') {
            setShowSetupImage({
              object,
              key: param,
              image,
            });
          }
        }, 250);
      });
    };
    if (settings?.useBackgroundImageSize || isPattern) {
      getFileByDataUrl(url).then(file => {
        getImageDpiByFile(file).then(dpi => {
          onApply(dpi);
        });
      });
    } else {
      onApply(settings?.dpi);
    }
  };

  const getRoundValue = num => {
    return Math.round(num * 100) / 100;
  };

  const onChangeTop = (o, value) => {
    setTopByPercentage(o, value, settings);
    onUpdatePersonalizationParam(o, 'y', o?.params?.y);
  };

  const onChangeLeft = (o, value) => {
    setLeftByPercentage(o, value, settings);
    onUpdatePersonalizationParam(o, 'x', o?.params?.x);
  };

  const onChangeWidth = (o, value) => {
    const [top, left] = [getTop(o, settings), getLeft(o, settings)];
    if (o?.params?.image && o?.personalization.includes('image')) {
      const ratio = o?.params.width / o.params.height;
      const [width, height] = [value, getRoundValue(value / ratio)];
      onUpdatePersonalizationParams(o, {
        width,
        height,
        // fix offset to center of object
        offsetX: width / 2,
        offsetY: height / 2,
      });
    } else {
      onUpdatePersonalizationParam(o, 'width', value);
    }
    onChangeTop(o, top);
    onChangeLeft(o, left);
  };

  const onChangeHeight = (o, value) => {
    const [top, left] = [getTop(o, settings), getLeft(o, settings)];
    if (o?.params?.image && o?.personalization.includes('image')) {
      const ratio = o?.params.width / o.params.height;
      const [width, height] = [getRoundValue(value * ratio), value];
      onUpdatePersonalizationParams(o, {
        width,
        height,
        // fix offset to center of object
        offsetX: width / 2,
        offsetY: height / 2,
      });
    } else {
      onUpdatePersonalizationParam(o, 'height', value);
    }
    onChangeTop(o, top);
    onChangeLeft(o, left);
  };

  const requestImageContainer = (
    object,
    param,
    component,
    widthControlButtons = true,
  ) => (
    <div style={{width: '100%'}}>
      <DropZoneWrapper
        key={`param=${param},id=${object?.id}`}
        onDrop={files => {
          const file = files?.[0];
          if (file?.size > mbToBytes(uploadLimit)) {
            showNotification({
              message: t('File size must be less than {{uploadLimit}}mb', {
                uploadLimit,
              }),
              isError: true,
            });
            return true;
          }
          //const url = URL.createObjectURL(file);
          //onChangeImage(object, param, url);
          const formData = new FormData();
          const name = `${object?.name}-${param}-${getSessionHashCode()}`;
          formData.append('name', name);
          formData.append('file', file);
          const onError = e => {
            showErrorNotification(e, showNotification, t('Upload failed'));
          };
          setLoading(true);
          uploadTemplateImage(cId, formData, onError)
            .then(response => {
              setLoading(false);
              const {data} = response;
              const url = data?.file?.url;
              setQuality({
                ...quality,
                [`${url}`]: data?.is_low_quality,
              });
              if (url) {
                const top = getTop(object, settings);
                const left = getLeft(object, settings);
                onChangeImage(object, param, url, top, left);
              }
            })
            .catch(() => {
              //showNotification({message: t('Upload failed'), isError: true});
              setLoading(false);
            });
        }}>
        <ItemBlock
          content={
            <S.AddDesign>
              {component ||
                (isBackgroundWithoutSource() ? null : (
                  <div className={'wrapper-add-design'}>
                    <div className={'add-plus'}>{'+'}</div>{' '}
                    <div className={'add-content'}>
                      {/*<h4>{t('Add design')}</h4>*/}
                      {(settings?.useBackgroundImageSize || isPattern) &&
                      object?.type === objectTypes.background ? null : (
                        <h5>
                          {t('Print area size')}{' '}
                          {pixelsToCm(settings?.width, settings?.dpi).toFixed(
                            2,
                          )}
                          {'x'}
                          {pixelsToCm(settings?.height, settings?.dpi).toFixed(
                            2,
                          )}
                          {'cm'}
                        </h5>
                      )}
                    </div>
                  </div>
                ))}
              {!isBackgroundWithoutSource() && widthControlButtons ? (
                <div className="buttons-wrapper">
                  {param === 'fillPatternImage' ? (
                    <button
                      className="position-btn"
                      onClick={event => {
                        event.stopPropagation();
                        object.params.fillPatternImage =
                          object.params.fillPatternImage ||
                          object.params.image ||
                          undefined;
                        object.params.image = undefined;
                        setShowSetupImage({
                          object,
                          key: param,
                          image: object?.params?.[param],
                        });
                      }}>
                      {/*{t('Change position')}*/}
                      <FontAwesomeIcon icon={faUpDownLeftRight} />
                    </button>
                  ) : null}
                  <div
                    className={'img-wr'}
                    onClick={event => {
                      event.stopPropagation();
                      // const presetPersonalizationData =
                      //   getPresetPersonalizationData();
                      // const presetItemData = presetPersonalizationData.find(
                      //   i => i?.id === object?.id,
                      // );
                      // onUpdatePersonalizationParams(
                      //   object,
                      //   presetItemData?.params,
                      // );
                      const resettedParams = {
                        ...(param === 'image'
                          ? {image: null, imageSrc: null}
                          : {}),
                        ...(param === 'fillPatternImage'
                          ? {fillPatternImage: null, fillPatternImageSrc: null}
                          : {}),
                      };
                      if (selectedObject?.id) {
                        setSelectedObject({
                          ...object,
                          params: {
                            ...object.params,
                            ...resettedParams,
                          },
                        });
                        onUpdatePersonalizationParams(object, resettedParams);
                      } else {
                        setSelectedObject({
                          ...object,
                          params: {
                            ...object.params,
                            ...resettedParams,
                          },
                        });
                      }
                    }}>
                    <img src={TrashIcon} alt={'trash-icon'} />
                  </div>
                </div>
              ) : null}
            </S.AddDesign>
          }
        />
      </DropZoneWrapper>
    </div>
  );

  const getImageContainer = data => {
    const {object, param} = data;
    const initialPersonalization = (presetPersonalization || []).find(
      i => i?.id === object?.id,
    );
    const isPersonalized =
      initialPersonalization?.params?.[param]?.src !==
      object?.params?.[param]?.src;
    const imageSrc = object?.params?.[param]?.src;
    const previewImgContainer =
      isPersonalized && imageSrc ? (
        <S.ImageWrapper>
          <img
            src={imageSrc}
            alt="icon"
            width={200}
            height={200}
            // objectFit="contain"
          />
        </S.ImageWrapper>
      ) : null;

    const requestImg = requestImageContainer(
      object,
      param,
      previewImgContainer,
    );
    const qualityKey = getImageUrlWithoutSuffix(object?.params[param]?.src);
    return (
      <S.RequestImageWrapper>
        {requestImg}
        {object?.params[param]?.src && quality?.[qualityKey] ? (
          <S.ErrorQuality>
            {t(
              'The quality of your photo is low! We recommend for a better result you upload another photo.',
            )}
          </S.ErrorQuality>
        ) : null}
        {object?.type === objectTypes.background &&
        (settings?.useBackgroundImageSize || isPattern) &&
        object?.params[param]?.src ? (
          <div style={{marginTop: 10, width: '100%'}}>
            <Dropdown
              data={supportedDPIs}
              value={String(settings?.dpi)}
              onChange={v => {
                onUpdateSettings({dpi: Number(v)});
              }}
              getOptionLabel={v => v}
              getOptionValue={v => v}
              label={t('DPI')}
            />
            <S.DpiInfoText>
              {t('For good printing results we recommend DPI 200 or 300')}
            </S.DpiInfoText>
          </div>
        ) : null}
      </S.RequestImageWrapper>
    );
  };

  const createTextInfoContainer = (
    <S.CreateTextInfo>
      {t('Click on the + icon above to create Text Field')}
    </S.CreateTextInfo>
  );

  const getObjectsOptions = o => {
    const personalizationList = o?.personalization || [];
    let block = (personalizationList || []).map(pItem => {
      return (
        <div key={pItem} style={{marginBottom: 10}}>
          {getPersonalizationInput({
            o,
            pItem,
            settings,
            selectedUnit,
            rotationValue,
            customFonts,
            onUpdatePersonalizationParam,
            onUpdatePersonalizationParams,
            onChangeTop,
            onChangeLeft,
            onChangeWidth,
            onChangeHeight,
            setRotationValue,
            getImageContainer,
            onUpdateSettings,
            t,
          })}
        </div>
      );
    });

    /* Not display repeated group params */
    // if (o?.personalization.find(item => isRepeatedGroupParam(o, item))) {
    //   return null;
    // }

    return (
      <S.BlockTools $isVisible={o?.personalization?.length} key={o?.id}>
        {block}
      </S.BlockTools>
    );
  };

  // const sidebarActionList = (
  //   <S.SidebarActions>
  //     {/*<ItemBlock title={"Size"} />*/}
  //     <h3>{t('Your Design')}</h3>
  //     <S.BgWr>
  //       <h4>{t('Front side')}</h4>
  //       {objectsOptions}
  //     </S.BgWr>
  //   </S.SidebarActions>
  // );

  const setupImage = (
    <SetupImage
      popupRef={setupImageRef}
      object={showSetupImage?.object}
      image={showSetupImage?.image}
      onClose={() => setShowSetupImage(null)}
      onResult={result => {
        const {x, y, offsetX, offsetY, scaleX, scaleY, rotation} = result;
        const changedParams = {
          fillPatternX: showSetupImage.object.params?.radius ? 0 : x,
          fillPatternY: showSetupImage.object.params?.radius ? 0 : y,
          fillPatternOffsetX: showSetupImage.object.params?.radius
            ? x
            : offsetX,
          fillPatternOffsetY: showSetupImage.object.params?.radius
            ? y
            : offsetY,
          fillPatternScaleX: scaleX,
          fillPatternScaleY: scaleY,
          fillPatternRotation: rotation,
          fillPatternImageWidth:
            showSetupImage.object.params?.fillPatternImage?.width,
          fillPatternImageHeight:
            showSetupImage.object.params?.fillPatternImage?.height,
        };
        const newObjectData = {
          ...showSetupImage?.object,
          key: new Date().getTime(),
          params: {...selectedObject?.params, ...changedParams},
        };
        setSelectedObject(newObjectData);
        if (showSetupImage?.object?.id) {
          onUpdatePersonalizationParams(showSetupImage?.object, changedParams);
        } else {
          addObject(newObjectData);
        }
      }}
    />
  );

  const imageSelectionList = objects.filter(
    i =>
      (i?.type === objectTypes.image || i?.type === objectTypes.clipart) &&
      i?.personalization?.length,
  );
  const imageSelectionListContainer = imageSelectionList?.length ? (
    <S.LayersListWrapper>
      {imageSelectionList.map(o => (
        <S.LayersListItem
          key={o?.id}
          size={85}
          $isSelected={selectedObject?.id === o?.id}
          onClick={() => setSelectedObject(o)}>
          {/*<S.ImageListImage*/}
          {/*  src={o?.params?.image?.src || o?.params?.fillPatternImage?.src}*/}
          {/*  alt={'image'}*/}
          {/*/>*/}
          <S.LayersListItemIcon size={65}>
            <PreviewObject object={o} width={65} height={65} />
          </S.LayersListItemIcon>
          <S.LayersListItemTrash
            onClick={e => {
              e.stopPropagation();
              onDeleteObject(o);
              setSelectedObject({...getInitialImage(settings)});
            }}>
            <FontAwesomeIcon icon={faTrash} size={'1x'} color={'lightgray'} />
          </S.LayersListItemTrash>
        </S.LayersListItem>
      ))}
      <S.LayersListItem
        key={'AddNewImage'}
        size={85}
        $isSelected={!selectedObject?.id}
        onClick={() => setSelectedObject({...getInitialImage(settings)})}>
        <S.AddSymbol>
          <FontAwesomeIcon
            icon={faPlus}
            size={'3x'}
            color={theme.primaryColor}
          />
        </S.AddSymbol>
        <S.AddLabel>{t('Add Photo')}</S.AddLabel>
      </S.LayersListItem>
    </S.LayersListWrapper>
  ) : null;

  const textSelectionListContainer = (
    <S.LayersListWrapper>
      {objects
        .filter(i => i?.type === objectTypes.text && i?.personalization?.length)
        .map(o => (
          <S.LayersListItem
            key={o?.id}
            size={85}
            $isSelected={selectedObject?.id === o?.id}
            onClick={() => setSelectedObject(o)}>
            <S.LayersListItemIcon size={60}>
              <PreviewObject object={o} width={60} height={60} />
            </S.LayersListItemIcon>
            <S.LayersListItemTrash
              onClick={e => {
                e.stopPropagation();
                onDeleteObject(o);
                setSelectedObject({...getInitialText(settings)});
              }}>
              <FontAwesomeIcon icon={faTrash} size={'1x'} color={'lightgray'} />
            </S.LayersListItemTrash>
          </S.LayersListItem>
        ))}
      <S.LayersListItem
        key={'AddNewText'}
        size={85}
        //$isSelected={!selectedObject?.id}
        onClick={() => {
          //setSelectedObject({...getInitialText(settings)})}
          addObject(
            activeTab === IMAGE_TAB
              ? {...getInitialImage(settings)}
              : {...getInitialText(settings)},
          );
        }}>
        <S.AddSymbol>
          <FontAwesomeIcon
            icon={faPlus}
            size={'3x'}
            color={theme.primaryColor}
          />
        </S.AddSymbol>
        <S.AddLabel>{t('Add Text')}</S.AddLabel>
      </S.LayersListItem>
    </S.LayersListWrapper>
  );

  const addNewObjectContainer = (
    <S.InputMobileWrapper>
      {getObjectsOptions({...selectedObject})}
      <S.AddNewButton>
        <button onClick={() => addObject({...selectedObject})}>
          {activeTab === IMAGE_TAB ? t('Add new image') : t('Add new text')}
        </button>
      </S.AddNewButton>
    </S.InputMobileWrapper>
  );

  const previewContainer = selectedObject ? (
    <S.PreviewWithLabelWrapper>
      <S.PreviewLabel>{t('Preview')}</S.PreviewLabel>
      <S.PreviewWrapper key={JSON.stringify(selectedObject?.params)}>
        <S.SelectedObject
          scale={Number(
            selectedObject?.params?.radius
              ? 1
              : selectedObject?.params?.width / selectedObject?.params?.height,
          )}>
          <PreviewObject
            previewRef={previewRef}
            object={selectedObject}
            width={340}
            height={84}
          />
        </S.SelectedObject>
      </S.PreviewWrapper>
    </S.PreviewWithLabelWrapper>
  ) : null;

  const sideBarMenuContainer = (
    <S.CreateSideBar>
      <S.CreateSideBarMenuList>
        {sidebarMenuList.map(item => (
          <S.CreateSideBarMenuItem
            $active={item.id === activeTab}
            key={item?.id}
            onClick={() => {
              if (item?.id === 1) {
                // disable click on Design tab
                return true;
              }
              setSelectedObject(
                item?.id === IMAGE_TAB
                  ? {...getInitialImage(settings)}
                  : item?.id === TEXT_TAB
                  ? {...getInitialText(settings)}
                  : null,
              );
              setActiveTab(item?.id);
            }}>
            <S.SidebarItemImg src={item?.img} alt="icon sidebar" />
            <span>{item.title}</span>
          </S.CreateSideBarMenuItem>
        ))}
        <S.CreateSideBarMenuLogoWrapper>
          <S.CreateSideBarMenuLogo src={theme?.appLogo} alt={'logo'} />
        </S.CreateSideBarMenuLogoWrapper>
      </S.CreateSideBarMenuList>
    </S.CreateSideBar>
  );

  return (
    <S.SidebarWr>
      {showSetupImage ? setupImage : null}
      {sideBarMenuContainer}
      <S.SidebarTab>
        {activeTab === 2 ? imageSelectionListContainer : null}
        {activeTab === 3 ? textSelectionListContainer : null}
        {activeTab === 2 && !selectedObject?.id ? (
          <S.RequestImageWrapper>
            {requestImageContainer(
              {...getInitialImage(settings)},
              'fillPatternImage',
              <S.RequestNewImageWrapper>
                <S.RequestNewImageInfoText>
                  {t('Upload your best photos using the option below')}
                </S.RequestNewImageInfoText>
                <S.RequestNewImageUploadButton>
                  {t('Upload')}
                </S.RequestNewImageUploadButton>
                <S.RequestNewImage>
                  <div>{t('Drag your photos here')}</div>
                  <div style={{fontStyle: 'italic'}}>
                    {t('Max File size {{uploadLimit}}mb', {uploadLimit})}
                  </div>
                  <div style={{fontStyle: 'italic'}}>
                    {t('We support jpg, png, pdf and tiff')}
                  </div>
                </S.RequestNewImage>
              </S.RequestNewImageWrapper>,
              false,
            )}
          </S.RequestImageWrapper>
        ) : null}
        {activeTab === BACKGROUND_TAB && !isBackgroundImageChanged() ? (
          <S.RequestImageWrapper>
            {requestImageContainer(
              {...bgObject},
              getImagePersonalizationParam(bgObject),
              <S.RequestNewImageWrapper>
                <S.RequestNewImageInfoText>
                  {isActivePattern
                    ? t('Upload your pattern using the option below.')
                    : t('Upload your image using the option below.')}
                </S.RequestNewImageInfoText>
                <S.RequestNewImageInfoText>
                  {isActivePattern
                    ? t(
                        'We will apply the pattern in repeat for choosen product.',
                      )
                    : t(
                        'We will use this image to cover all the product layout as a background.',
                      )}
                </S.RequestNewImageInfoText>
                <S.RequestNewImageUploadButton>
                  {t('Upload')}
                </S.RequestNewImageUploadButton>
                <S.RequestNewImage>
                  <div>
                    {activeTab === BACKGROUND_TAB && isPattern
                      ? t('Drag your pattern here')
                      : t('Drag your photos here')}
                  </div>
                  <div style={{fontStyle: 'italic'}}>
                    {t('Max File size {{uploadLimit}}mb', {uploadLimit})}
                  </div>
                  <div style={{fontStyle: 'italic'}}>
                    {t('We support jpg, png, pdf and tiff')}
                  </div>
                </S.RequestNewImage>
              </S.RequestNewImageWrapper>,
              false,
            )}
          </S.RequestImageWrapper>
        ) : null}
        {activeTab === TEXT_TAB && !selectedObject?.id
          ? createTextInfoContainer
          : null}
        {/*{activeId === IMAGE_TAB || activeId === TEXT_TAB ? previewContainer : null}*/}
        {/*{activeId > 1 && !selectedObject?.id ? addNewObjectContainer : null}*/}
        {activeTab > 1 && selectedObject?.id
          ? getObjectsOptions(
              selectedObject
                ? objects.find(i => i?.id === selectedObject?.id)
                : null,
            )
          : null}
      </S.SidebarTab>
    </S.SidebarWr>
  );
};
