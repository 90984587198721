import styled from 'styled-components';

export const CanvasWrapper = styled.div`
  display: flex;
  min-width: 1px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const RootContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: ${props => props.theme.spaceNormal};
  width: 100%;
  height: 100%;
  max-height: ${p =>
    p?.$isVertical ? 'calc(50vh - 70px)' : 'calc(100vh - 70px)'};
`;
