import React, {useEffect, useRef, useImperativeHandle} from 'react';
import CanvasView from '../canvasView/CanvasView';
import * as S from './styled';

export const ProductPreview = React.forwardRef((props, ref) => {
  const {
    settings,
    objects,
    canvasPreviewRef,
    previewWithRulerRef,
    objectsRefs,
    scale,
    setScale,
    editMode,
    selectedObject,
    setSelectedObject,
    setShapeChange,
    canvasRotation,
    isVertical,
    transformMode,
  } = props;
  const visibleAreaRef = useRef(null);
  const rulerWidth = 20 / scale;

  const updateScaleValue = () => {
    const scaleX = visibleAreaRef?.current?.clientWidth / settings?.width;
    const scaleY =
      (visibleAreaRef?.current?.clientHeight - 60) / settings?.height;
    const scale = Math.min(scaleX, scaleY);
    setScale(scale);
  };

  const size = parseInt(scale * 100);
  const setSize = v => setScale(v / 100);

  useImperativeHandle(ref, () => ({
    restoreFitToPage() {
      if (settings && visibleAreaRef?.current) {
        updateScaleValue();
      }
      if (previewWithRulerRef?.current && visibleAreaRef?.current) {
        previewWithRulerRef.current.setPosition({
          x:
            (visibleAreaRef.current.clientWidth -
              settings.width * scale -
              rulerWidth * scale) /
            2,
          y:
            (visibleAreaRef.current.clientHeight -
              settings.height * scale -
              rulerWidth * scale) /
            2,
        });
      }
    },
  }));

  useEffect(() => {
    if (settings && visibleAreaRef?.current) {
      updateScaleValue();
    }
  }, [visibleAreaRef?.current, settings]);

  const stageView = visibleAreaRef?.current ? (
    <CanvasView
      canvasPreviewRef={canvasPreviewRef}
      previewWithScaleRef={previewWithRulerRef}
      {...settings}
      visibleArea={visibleAreaRef?.current}
      objects={objects}
      scale={scale}
      objectsRefs={objectsRefs}
      withRuler
      rulerWidth={rulerWidth}
      editMode={editMode}
      showTransform
      selectedIds={[selectedObject?.id]}
      onObjectClick={o => {
        setSelectedObject(o);
      }}
      setShapeChange={setShapeChange}
      canvasRotation={canvasRotation}
      onZoomInClick={() => {
        if (size > 0) {
          setSize(size - 1);
        }
      }}
      onZoomOutClick={() => {
        if (size < 100) {
          setSize(size + 1);
        }
      }}
      transformMode={transformMode}
    />
  ) : null;

  return (
    <S.RootContainer
      $maxHeight={visibleAreaRef?.current?.clientHeight || 100}
      $isVertical={isVertical}>
      <S.CanvasWrapper
        scale={scale}
        ref={visibleAreaRef}
        $isVertical={isVertical}
        $maxHeight={visibleAreaRef?.current?.clientHeight}>
        {settings && scale ? stageView : null}
      </S.CanvasWrapper>
    </S.RootContainer>
  );
});
