import styled from 'styled-components';

export const LayoutRoot = styled.main`
  height: 100vh;
  width: 100%;
  max-height: 100vh;
  background-color: white;
  overflow: hidden;
`;

export const LayoutWr = styled.div`
  //display: grid;
  //grid-template-columns: 500px auto;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 70px);
  ${props => props.theme.screenSize.xl} {
    max-height: calc(100vh - 50px);
  }
`;

export const VerticalLayoutWr = styled.div`
  //display: grid;
  //grid-template-columns: 500px auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 70px);
  ${props => props.theme.screenSize.xl} {
    max-height: calc(100vh - 50px);
  }
`;

export const ColumSidebar = styled.div`
  pointer-events: ${p => (p?.isLoading ? 'none' : 'all')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: ${p => (p?.$isVertical ? '100%' : 'calc(100vh - 70px)')};
  min-width: ${p => (p?.$isVertical ? '100%' : p?.$sideBarWidth + 'px')};
  max-width: ${p => (p?.$isVertical ? '100%' : p?.$sideBarWidth + 'px')};
  flex: 1;
  overflow: hidden;
  ${props => props.theme.screenSize.xl} {
    max-height: ${p => (p?.$isVertical ? '100%' : 'calc(100vh - 50px)')};
  }
`;

export const ColumCanvas = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: ${p =>
    p?.$isVertical ? 'calc(50vh - 70px)' : 'calc(100vh - 70px)'};
  min-height: ${p =>
    p?.$isVertical ? 'calc(50vh - 70px)' : 'calc(100vh - 70px)'};
  width: ${p =>
    p.$isVertical ? '100%' : `calc(100vw - ${p?.$sideBarWidth}px)`};
  ${props => props.theme.screenSize.xl} {
    max-height: ${p => (p?.$isVertical ? 'calc(50vh)' : 'calc(100vh - 50px)')};
    min-height: ${p => (p?.$isVertical ? 'calc(50vh)' : 'calc(100vh - 50px)')};
  }
`;

export const EditorArea = styled.div`
  height: calc(100vh - 128px);
  ${props => props.theme.screenSize.xl} {
    height: calc(100vh - 108px);
  }
  @media (max-width: 770px) {
    height: calc(100vh - 153px);
  }
  width: 100%;
  background-color: #f6f6f6;
`;
