import ExifReader from 'exifreader';

export const initImageWithCache = (o, ref) => {
  const image = o?.params?.image || o?.params?.fillPatternImage;
  if (image?.src) {
    image.onload = () => {
      let repeat = ref?.attrs?.fillPatternRepeat;
      ref.setAttr('fillPatternRepeat', '');
      ref.setAttr('fillPatternRepeat', repeat);
      ref.cache();
    };
  }
};

export const getPreviewDataUrl = (
  ref,
  prevWithRulerRef,
  settings,
  scale,
  pixelRatio,
) => {
  const x = prevWithRulerRef.getPosition()?.x + ref.getPosition()?.x * scale;
  const y = prevWithRulerRef.getPosition()?.y + ref.getPosition()?.y * scale;
  return ref.toDataURL({
    pixelRatio: pixelRatio,
    x,
    y,
    width: settings?.width * scale,
    height: settings?.height * scale,
  });
};

export const getImageDpiByFile = async file => {
  const tags = await ExifReader.load(file);
  //console.log('Image tags', tags);
  // PNG tags
  const pixelUnitsTag = tags?.['Pixel Units'];
  const ppuXTag = tags?.['Pixels Per Unit X'];
  const ppuYTag = tags?.['Pixels Per Unit Y'];
  // JPG tags
  const resolutionUnitTag = tags?.['ResolutionUnit'];
  const resolution_UnitTag = tags?.['Resolution Unit'];
  const xResolution = tags?.['XResolution'];
  const yResolution = tags?.['YResolution'];
  if (ppuXTag?.value && ppuYTag?.value && pixelUnitsTag?.value === 1) {
    const conversionFactor = 0.0254; // For meters,1 inch = 0.0254 meters
    const dpiX = Math.round(ppuXTag.value * conversionFactor);
    const dpiY = Math.round(ppuYTag.value * conversionFactor);
    return Math.round((dpiX + dpiY) / 2);
  } else if (xResolution?.value && Array.isArray(xResolution?.value)) {
    const dpiX = xResolution?.value?.[0] / xResolution?.value?.[1];
    const dpiY = yResolution?.value?.[0] / yResolution?.value?.[1];
    return Math.round((dpiX + dpiY) / 2);
  } else if (xResolution?.value && xResolution?.value !== 1) {
    const dpiX = xResolution?.value;
    const dpiY = yResolution?.value;
    return Math.round((dpiX + dpiY) / 2);
  } else {
    return 72;
  }
};

export const patchOverSizedImages = imageLayer => {
  // max size for display 16384 but it freezes on transform a must be a little reduced
  // 16384 - 1048 * 2 = 14336; 16384 - 1048 * 4 = 12288;
  const maxSideSum = 12288;
  const {params} = imageLayer || {};
  const imageSrc = params?.image?.src || params?.fillPatternImage?.src;
  if (imageSrc && params.width + params?.height > maxSideSum) {
    const scaleValue = (params?.width + params?.height) / maxSideSum;
    const width = params?.width / scaleValue;
    const height = params?.height / scaleValue;
    params.width = width;
    params.height = height;
  }
};
