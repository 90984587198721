import React from 'react';
import {useTranslation} from 'react-i18next';
import {useTheme} from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faUpRightAndDownLeftFromCenter,
  faHand,
  faEdit,
  faRotate,
  faArrowRotateLeft,
  faArrowRotateRight,
} from '@fortawesome/free-solid-svg-icons';
import {getZoomInIcon, getZoomOutIcon} from '../../helpers/iconHelper';
import * as S from './styled';

export const Footer = props => {
  const {
    size,
    setSize,
    onFitToPageClicked,
    onAddToCartClick,
    onPreviewClick,
    editMode,
    setEditMode,
    canvasRotation,
    setCanvasRotation,
    isAddToCartDisabled,
    sideBarWidth,
    isVertical,
    windowWidth,
    onRedo,
    onUndo,
    isUndoActive,
    isRedoActive,
    isShowPreviewButton,
  } = props;
  const {t} = useTranslation(['footer']);
  const theme = useTheme();

  const editModesContainer = (
    <div>
      <FontAwesomeIcon
        icon={faEdit}
        onClick={() => setEditMode(true)}
        color={editMode ? theme.primaryColor : 'lightGray'}
      />
      <FontAwesomeIcon
        icon={faHand}
        onClick={() => setEditMode(false)}
        color={!editMode ? theme.primaryColor : 'lightGray'}
      />
    </div>
  );

  const rotateButton = (
    <S.RotateIconWrapper
      onClick={() => {
        //setCanvasRotation(canvasRotation < 270 ? canvasRotation + 90 : 0)
      }}>
      <FontAwesomeIcon
        className={'rotate-icon'}
        icon={faRotate}
        color={theme.primaryColor}
      />
    </S.RotateIconWrapper>
  );

  const redoUndoButtons = (
    <S.RedoUndoWrapper $undoActive={isUndoActive} $redoActive={isRedoActive}>
      <FontAwesomeIcon
        className="svg-left"
        icon={faArrowRotateLeft}
        onClick={onUndo}
      />
      <FontAwesomeIcon
        className="svg-right"
        icon={faArrowRotateRight}
        onClick={onRedo}
      />
    </S.RedoUndoWrapper>
  );

  const zoomInButton = (
    <S.ZoomIconWrapper
      onClick={() => {
        if (size > 0) {
          setSize(size - 1);
        }
      }}>
      <S.ZoomIcon src={getZoomInIcon('black')} alt={'zoomInIcon'} />
    </S.ZoomIconWrapper>
  );

  const zoomOutButton = (
    <S.ZoomIconWrapper
      onClick={() => {
        if (size < 100) {
          setSize(size + 1);
        }
      }}>
      <S.ZoomIcon src={getZoomOutIcon('black')} alt={'zoomOutIcon'} />
    </S.ZoomIconWrapper>
  );

  const resizeButton = (
    <S.ResizeIconWrapper>
      <FontAwesomeIcon
        className={'resize-icon'}
        icon={faUpRightAndDownLeftFromCenter}
        onClick={onFitToPageClicked}
      />
    </S.ResizeIconWrapper>
  );

  const previewButton = isShowPreviewButton ? (
    <S.PreviewButton>
      <button onClick={onPreviewClick}>{t('Preview')}</button>
    </S.PreviewButton>
  ) : null;

  const addToCartButton = (
    <S.AddToCart disabled={isAddToCartDisabled}>
      <button disabled={isAddToCartDisabled} onClick={onAddToCartClick}>
        {t('Add to cart')}
      </button>
    </S.AddToCart>
  );

  const zoomButtonsContainer = (
    <S.CounterWr>
      {zoomInButton}
      {zoomOutButton}
      {/*{resizeButton}*/}
    </S.CounterWr>
  );

  const addAndPrevButtonsContainer = (
    <S.AddAndPrevButtons $isVertical={isVertical}>
      {previewButton}
      {addToCartButton}
    </S.AddAndPrevButtons>
  );

  const iconsContainer = (
    <S.IconsRow>
      {/*<S.EditModeWr>{rotateButton}</S.EditModeWr>*/}
      {redoUndoButtons}
      {/*{zoomButtonsContainer}*/}
      {/*<div style={{width: 30}} />*/}
    </S.IconsRow>
  );

  return (
    <S.Footer $isVertical={isVertical}>
      <S.CanvasButtonsArea $isVertical={isVertical}>
        {iconsContainer}
        {addAndPrevButtonsContainer}
        <div style={{width: 0, height: 0, flex: 1}} />
      </S.CanvasButtonsArea>
    </S.Footer>
  );
};
